<!-- can be used for dailychallenge, pet battle, and video lesson buttons -->
<template>
  <router-link
    class="button"
    :class="_class"
    :to="{name: routerLinkName}">
    <div class="bg-gradient"></div>
    <div class="text">
      <h2 class="text-main">
        {{ title }}
      </h2>
      <p class="text-sub">
        {{ subtitle }}
      </p>
    </div>
  </router-link>
</template>

<script>


export default {
  name: 'ProfilePageButton',
  components: {
  },
  props: {
    type: String,
    title: String,
    subtitle: String,
    routerLinkName: String,
  },
  data() {
    return {
    };
  },
  computed: {
    _class() {
      if (this.type === 'malaySpeaking') return 'malay-speaking';
      if (this.type === 'dailyChallenge') return 'daily-challenge';
      if (this.type === 'videoLessons') return 'video-lessons';
      if (this.type === 'stories') return 'stories';
      return '';
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .button {
    text-decoration: none;
    &:hover {
      transform: scale(1.02);
    }
    transition: 0.2s all linear;
    cursor: pointer;
    position: relative;
    background-position: center;
    background-size: cover;
    border-radius: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .bg-gradient {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 1;
      height: 60%;
      border-radius: 0.75rem;
      overflow: hidden;
    }
    .text {
      z-index: 2;
      border: 4px solid #00000024;
      border-radius: 0.75rem;
      height: 95%;
      width: 95%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      color: #fff;
      &-main {
        font-size: 2rem;
        margin-bottom: -0.75rem;
        text-shadow: 0px 4px rgb(0 0 0 / 60%);
      }
      &-sub {
        font-size: .9rem;
        margin-bottom: 0.25rem;
        text-shadow: 2px 2px rgb(0 0 0 / 60%);
      }
    }
  }
  .malay-speaking {
    position: relative;
    background-image: url("../../../../assets/profile/btn-oral-malay.jpg");
    width: 350px;
    height: 250px;

    .bg-gradient {
      background: linear-gradient(to top, #00a2ffb0, transparent)
    }
    .text-main {
      font-size: 1.75rem;
    }
  }

  .daily-challenge {
    margin-right: 2rem;
    background-image: url("../../../../assets/profile/btn-dailychallenge.jpg");
    width: 480px;
    height: 250px;

    .bg-gradient {
      background: linear-gradient(to top, #ff6b00b0, transparent)
    }
    .text-main {
      font-size: 1.75rem;
    }
  }

  .video-lessons {
    position: relative;
    background-image: url("../../../../assets/profile/btn-video.jpg");
    width: 350px;
    height: 250px;

    .bg-gradient {
      background: linear-gradient(to top, #00ffe3b0, transparent)
    }
    .text-main {
      font-size: 1.75rem;
    }
  }
  .stories {
    position: relative;
    background-image: url("../../../../assets/profile/btn-story.jpg");
    width: 350px;
    height: 250px;

    .bg-gradient {
      background: linear-gradient(to top, #00ffe3b0, transparent)
    }
    .text-main {
      font-size: 1.75rem;
    }
  }
  @media only screen and (max-width: 1560px), screen and (max-height: 800px) {
    .daily-challenge {
      width: 430px;
      height: 225px;
      margin-right: 1.5rem;
      .text-main {
        font-size: 1.65rem;
      }
      .text-sub {
        font-size: .85rem;
      }
    }
    .video-lessons, .stories, .malay-speaking {
      width: 295px;
      height: 225px;
      .text-main {
        font-size: 1.65rem;
      }
      .text-sub {
        font-size: .85rem;
      }
    }
  }
  @media only screen and (max-width: 1366px), screen and (max-height: 690px) {
    .daily-challenge {
      width: 380px;
      height: 200px;
      margin-right: 1rem;
      .text-main {
        font-size: 1.35rem;
      }
      .text-sub {
        font-size: .75rem;
      }
    }
    .video-lessons, .stories, .malay-speaking {
      width: 255px;
      height: 200px;
      .text-main {
        font-size: 1.35rem;
      }
      .text-sub {
        font-size: .75rem;
      }
    }
  }
  @media only screen and (max-width: 1080px) {
    .daily-challenge {
      width: 350px;
      margin-right: .75rem;
      .text-main {
        font-size: 1.25rem;
      }
      .text-sub {
        font-size: .7rem;
      }
    }
    .video-lessons, .stories, .malay-speaking {
      width: 225px;
      .text-main {
        font-size: 1.25rem;
      }
      .text-sub {
        font-size: .7rem;
      }
    }
  }
  @media only screen and (max-width: 1010px) {
    .daily-challenge {
      width: 320px;
      height: 180px;
      margin-right: .5rem;
      .text-main {
        font-size: 1.1rem;
      }
      .text-sub {
        margin-top: 0.25rem;
        font-size: .6rem;
      }
    }
    .video-lessons, .stories, .malay-speaking {
      width:200px;
      height: 180px;
      .text-main {
        font-size: 1.1rem;
      }
      .text-sub {
        margin-top: 0.25rem;
        font-size: .6rem;
      }
    }
  }
  @media only screen and (max-width: 960px), screen and (max-height: 620px) {
    .daily-challenge, .video-lessons, .stories, .malay-speaking {
      width: 95%;
      height: 120px;
      margin-right: 0;
      margin-bottom: 1rem;
      .text {
        justify-content: center;
      }
      .text-main {
        font-size: 1.75rem;
      }
      .text-sub {
        font-size: 1rem;
        display: none;
      }
    }
    .daily-challenge {
      .bg-gradient {
        background: linear-gradient(to top, rgba(255, 107, 0, 0.6901960784), #f3007699);
        height: 100%;
      }
    }
    .video-lessons, .stories, .malay-speaking {
      .bg-gradient {
        background: linear-gradient(to top, rgba(0, 255, 227, 0.6901960784), #00ffb685);
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 505px) {
    .daily-challenge, .video-lessons, .stories {
      .text-main {
        font-size: 1.5rem;
      }
      .text-sub {
        font-size: .75rem;
      }
    }
  }
</style>
