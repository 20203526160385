 <template>
  <div id="app" class="notranslate">
    <!-- OLD ROUTES -->
    <div class="old-routes--wrapper" v-if="!isNewRoute">
    <!-- Header -->
    <the-header v-if="authenticated"></the-header>
      <keep-alive
        :include="['LibraryMain', 'LearningTrackMain']">
<!--         <transition
          :enter-active-class="enterActiveClass"
          :leave-active-class="leaveActiveClass"> -->
        <router-view class="animated faster"></router-view>
        <!-- </transition> -->
      </keep-alive>
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster">
      <sidebar-right
        v-if="this.$store.state.isSidebarShown"
        v-on:close-sidebar="toggleSidebar"
      ></sidebar-right>
    </transition>
    <!-- NEW ROUTES -->
    <div v-if="isNewRoute" class="new-app">
      <challenges-overview></challenges-overview>
      <!-- used to show parent authentication, etc -->
<!--       <full-screen-modal></full-screen-modal> -->
      <!-- For new nav -->
      <new-the-nav class="new-nav" v-if="authenticated"
      ></new-the-nav>
      <div class="new-content-container"
        :class="getBG()">
        <div class="new-content-container--wrapper">
          <!-- currency container showing diamond and gold is always present -->
          <currency-container></currency-container>
          <keep-alive>
            <router-view class="new-content animated faster"></router-view>
            <!--
              blurring effect is caused by double routerview
              will be fixed once we go directly to new website
            -->
          </keep-alive>
          <!-- widgets are on the right -->
          <widget-container
            v-show="isWidgetsShown"
            class="new-widget"
            :component-names="componentNames">
          </widget-container>
        </div>
      </div>
      <mobile-nav-more-menu v-if="isMobileNavMoreMenuShown"></mobile-nav-more-menu>
    </div>
    <!-- showGlobalLoadingScreen is true by default so if user loads in directly without going through login-main, loading page is shown  -->
    <loading-screen
      :prop-show="showGlobalLoadingScreen"
      :min-wait-time="1000"></loading-screen>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';

import ChallengesOverviewComponent from './components/new/challenges/OverviewComponent.vue';
import TheNavComponent from './components/new/nav/TheNavComponent.vue';
import MobileNavMoreMenuComponent from './components/new/nav/components/MobileNavMoreMenuComponent.vue';
import TheHeaderComponent from './components/header/TheHeaderComponent.vue';
import SidebarRight from './components/header/SidebarRight.vue';
import LoadingScreenComponent
  from './components/common/LoadingScreenComponent.vue';
import WidgetContainerComponent
  from './components/new/widgets/WidgetContainerComponent.vue';
import CurrencyContainerComponent
  from './components/new/widgets/CurrencyContainerComponent.vue';

import {routeNameToWidgets} from './widgetNamesToLoad';

export default {
  name: 'App',
  components: {
    NewTheNav: TheNavComponent,
    MobileNavMoreMenu: MobileNavMoreMenuComponent,
    SidebarRight: SidebarRight,
    theHeader: TheHeaderComponent,
    LoadingScreen: LoadingScreenComponent,
    WidgetContainer: WidgetContainerComponent,
    CurrencyContainer: CurrencyContainerComponent,
    ChallengesOverview: ChallengesOverviewComponent,
  },
  computed: {
    ...mapState(['authenticated', 'showGlobalLoadingScreen', 'isSidebarShown', 'isWidgetsShown', 'isMobileNavMoreMenuShown']),
    ...mapState('widgets', ['componentNames']),
    // website revamp; eventually remove this
    isNewRoute() {
      // login route
      if (this.$route.fullPath.startsWith('/nusa/login')) {
        return false;
      }
      return this.$route.fullPath.startsWith('/nusa') || this.$route.fullPath.startsWith('/new') || this.$route.fullPath === '/';
    },
  },
  data() {
    return {
      leaveActiveClass: '',
      enterActiveClass: '',
    };
  },
  mounted() {
  },
  methods: {
    ...mapMutations('widgets', ['setComponentNames']),
    toggleSidebar() {
      this.$store.state.isSidebarShown = !this.$store.state.isSidebarShown;
    },
    getBG() {
      return {
        'bg-missions': this.$route.name === 'new-missions-overview',
        'bg-events': this.$route.name === 'new-events-overview',
      };
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        if (from) {
          if ((from.path === '/shop') || (to.path === '/library') ||
              ((from.path === '/pet') && ((to.path === '/') ||
              (to.path === '/learningtrack'))) ||
              ((from.path === '/') && (to.path === '/learningtrack'))) {
            this.leaveActiveClass = 'slideOutLeft';
            this.enterActiveClass = 'slideInRight';
          } else if ((from.path === '/library') ||
              (to.path === '/shop') ||
              ((from.path ==='/learningtrack') && ((to.path === '/') ||
              (to.path === '/pet'))) ||
              ((from.path ==='/') && (to.path === '/pet'))) {
            this.leaveActiveClass = 'slideOutRight';
            this.enterActiveClass = 'slideInLeft';
          } else {
            this.leaveActiveClass = 'fadeOut';
            this.enterActiveClass = 'fadeIn';
          }
        }
        // adds widget components to the side
        if (this.isNewRoute) {
          const componentNames = (to.name in routeNameToWidgets) ? routeNameToWidgets[to.name] : [];
          this.setComponentNames(componentNames);
        }
      },
    },
  },
};
</script>

<!-- global styles -->
<style lang="scss">

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#app {
  width: 100vw;
  display: flex;
  background-color: #fff;
  color: #555;
  font-family: 'Lato', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  height:100%;
  background: #e9e9e9;
}

a {
  color: rgba(255, 255, 255, 0.4);
}

li{
  list-style: none;
}

.row {
  max-width: 1140px;
  margin: 0 auto;
}

input,
textarea,
button,
select,
a,
.tap-btn-highlight {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.chinese-character {
  font-family:
    "WenQuanYi Micro Hei", "UKai", "STKaiTi", "KaiTi", "Heiti SC" !important;
}

.malay-character {
  font-family: mulish, century-gothic;
}

.hypy {
  font-family: 'STHeiti', 'Heiti SC' !important;
}

/* SECTION */
section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
}

.fixed-component {
  position: fixed;
  width: 100%;
  top: 64px;
  bottom: 58px;
}

.scrollable-component {
    height: 100%;
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
}

// NEW ROUTES CSS
.new-app {
  width: 100vw;
  font-family: "Baloo Da 2";
  .new-nav {
    width: 235px;
    position: fixed;
    top: 0;
    left: 0;
  }
  .new-content {
    width: calc(100% - 337px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-container {
      position: fixed;
      display: flex;
      height: 100%;
      margin-left: 235px;
      width: calc(100% - 235px);
      background: linear-gradient(#13397B, #2F4EA9);
      &--wrapper {
        position: relative;
        width: 100%;
        background: linear-gradient(to right, #233167, transparent);
      }
    }
  }
  .bg-missions {
    background-image: url("./assets/bg-missions.jpg");
    background-size: cover;
    background-position: bottom right;
  }
  .bg-events {
    background-image: url("./assets/bg-events.jpg");
    background-size: cover;
    background-position: bottom right;
  }
  .new-widget {
    width: 337px;
    float: right;
  }
}


// to delete after transfer to new routes
.old-routes--wrapper {
  flex: 1;
}

@media only screen and (max-width: 1366px),
            screen and (max-height: 870px) {
  .new-app {
    .new-nav {
      width: 200px;
    }
    .new-content {
      width: calc(100% - 275px);
      &-container {
        margin-left: 200px;
        width: calc(100% - 200px);
      }
    }
    .new-widget {
      width: 275px;
    }
  }
}
@media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
  .new-app {
    .new-nav {
      width: 175px;
    }
    .new-content {
      width: calc(100% - 225px);
      &-container {
        margin-left: 175px;
        width: calc(100% - 175px);
      }
    }
    .new-widget {
      width: 225px;
    }
  }
}

@media only screen and (max-width: 960px),
           screen and (max-height: 620px) {
  .new-app {
    .new-nav {
    }
    .new-content {
      width: 100%;
      &-container {
        background: linear-gradient(to top, #13397B, #2F4EA9);
        margin-left: 0;
        width: 100%;
        height: calc(100% - 55px);
        &--wrapper {
          background: linear-gradient(to bottom right, rgba(29, 36, 77, 0.7882352941), rgba(21, 24, 41, 0.2352941176));
        }
      }
    }
    .new-widget {
      display: none;
    }
  }
}

</style>
