<template>
  <block-container
  :title="titleText"
  subtitle="Change Avatar"
  fa-icon="user"
  >
    <ul class="avatar__component--body">
      <li v-for="(_a, index) in avatars"
        :key="index"
        @click="updateAvatar(_a)"
        :class="{active: _a === avatar}">
        <img class="avatar-img"
          :src='"../../../assets/avatars/" + _a + ".png"' />
      </li>
    </ul>
  </block-container>
</template>

<script>

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import axios from 'axios';

import {mapState, mapMutations} from 'vuex';

// const AVATARS = ['jiejie', 'qiqi', 'yangyang', 'xiaojiling', 'yuyu', 'haidaoxiongmao', 'superpandabrother', 'superpandasister', 'teachertortoise', 'robotwolf', 'shark', 'fish', 'octopus'];

const AVATARS = ['jiejie', 'qiqi', 'yangyang', 'xiaojiling', 'haidaoxiongmao', 'robotwolf', 'shark', 'octopus'];

export default {
  name: 'NewPlayAvatar',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      fetching: false,
      avatars: AVATARS,
    };
  },
  computed: {
    ...mapState(['studentId', 'avatar', 'subject']),
    titleText() {
      return this.subject === 'chinese' ? '更改头像' : 'Tukar Avatar';
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setAvatar']),
    updateAvatar(avatar) {
      this.setAvatar(avatar);
      axios
          .post(`/schoolclass/student/${this.studentId}/avatar/`, {avatar: avatar});
    },
  },
};
</script>


<style lang="scss" scoped>
  .avatar__component {
    &--body {
      height: 100%;
      background: linear-gradient(to bottom right, #1d244dc9, #1518293c);
      padding: 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      grid-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      justify-content: center;
      width: 100%;
      grid-gap: 1.5rem;
      li {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
        background: #f525fa;
        border-radius: 0.75rem;
        border: 5px solid #fff;
        img {
          width: 100%;
          background: linear-gradient(to top, #ffd600, transparent);
        }
        &:hover {
          border: 5px solid #ffaf50;
        }
      }
      .active {
        border: 5px solid #ffaf50;
      }
    }
  }
    @media only screen and (max-width: 1500px) {
      .avatar__component--body {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
    @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
      .avatar__component {
        &--header {
          &-text {
            margin: 2rem auto 1.5rem 4rem;
            &-main {
              font-size: 2rem;
              line-height: 2rem;
            }
            &-sub {
              font-size: 1rem;
              line-height: 1.9rem;
            }
          }
        }
        &--body {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        }
      }
    }
    @media only screen and (max-width: 1300px) {
      .avatar__component--body {
      }
    }
    @media only screen and (max-width: 1080px) {
      .avatar__component--body {
      }
    }
    @media only screen and (max-width: 960px),
      screen and (max-height: 620px) {
      .avatar__component--body {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }

  @media only screen and (max-width: 780px) {
    .avatar__component--body {
    }
  }
  @media only screen and (max-width: 680px) {
    .avatar__component--body {
      grid-gap: 1rem;
      padding: 1.5rem;
    }
    .avatar {
      &__component {
        &--body {
          grid-template-columns: 1fr 1fr 1fr;
          background: none;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 520px) {
    .avatar__component--body {
    }
  }

</style>
