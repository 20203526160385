<template>
  <block-container
    title="Kosa Kata"
    subtitle="Vocabulary"
    previous-page-name="new-learn-overview"
    fa-icon="book"
    >
    <div class="body-content">
      <div class="level-btns">
        <div v-for="(level, index) in validLevels" :key="index"
          @click="clickLevel(level)"
          :class="{'level-btn--active': selectedLevel === level }"
          class="level-btn"
        >
          {{ level | shorternLevel }}
        </div>
      </div>
      <div class="oral-index__container">
        <router-link
          v-for="(chapter, index) in chapters" :key="index"
          :to="{name: 'new-malay-vocab-quiz', params: {quizId: chapter.quiz_id}}"
          class="oral-index__container--item">
          <div class="oral-index__container--item-img--container">
            <img :src="chapter.image_url" class="oral-index__container--item-img">
          </div>
          <div class="oral-index__container--item-text">
            <span class="oral-index__container--item-text-title">
              {{ chapter.title | splitCapitalise}}
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </block-container>
</template>

<script>

import BlockContainerComponent
  from './../common/BlockContainerComponent.vue';
// import RatingComponent from '../../common/RatingComponent.vue';

import axios from 'axios';

import {mapState, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'NewMalayTopicIndex',
  components: {
    BlockContainer: BlockContainerComponent,
    // Rating: RatingComponent,
  },
  props: {
    topicId: Number,
  },
  data() {
    return {
      chapters: [],
    };
  },
  computed: {
    ...mapGetters(['validLevels']),
    // ...mapState(['level', 'quizzesCompletedInfo']),
    ...mapState(['level']),
    ...mapState('guidedReading', ['malayChapters', 'selectedLevel']),
  },
  mounted() {
    if (this.selectedLevel === '') {
      this.setSelectedLevel(this.level);
    }
    if (!this.malayChapters[this.selectedLevel]) {
      this.pullTextbookChapter(this.selectedLevel);
    } else {
      this.chapters = this.malayChapters[this.selectedLevel];
    }
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('guidedReading', ['setMalayChapters', 'setSelectedLevel']),
    // returns 0 to 3 stars; 0 is default
    // getStars(quizId) {
    //   try {
    //     return this.quizzesCompletedInfo['oral_chapter_sentences'][quizId]['stars'];
    //   } catch (err) {
    //     return 0;
    //   }
    // },
    clickLevel(level) {
      if (level === this.selectedLevel) return;
      this.setSelectedLevel(level);
      this.pullTextbookChapter(level);
    },
    pullTextbookChapter(level) {
      this.setShowGlobalLoadingScreen(true);
      axios
          .post('/vocab/malaychapter/all/', {level: level})
          .then((response) => {
            this.setMalayChapters({
              level: this.selectedLevel,
              chapters: response.data,
            });
            this.chapters = response.data;
            console.log(this.chapters);
            this.setShowGlobalLoadingScreen(false);
          })
          .catch((error) => {
            console.log('having issue pulling textbook chapters');
            console.log('error', error);
            this.setShowGlobalLoadingScreen(false);
          });
    },
  },
};

</script>

<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .level {
    &-btn {
      border-bottom: 4px solid #193295;
      border-right: 4px solid #193295;
      border-top: 4px solid #5879fe;
      border-left: 4px solid #5879fe;
      color: #fff;
      background: #2c57ff;
      padding: .1rem;
      width: 13%;
      border-radius: 0.5rem;
      font-size: .9rem;
      margin-right: .5rem;
      text-align: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 0px 1px 3px #ff00dd;
      }
      &--active {
        color: #fff;
        background: #ffa025;
        font-weight: 600;
        border-top: 4px solid #bf6800;
        border-left: 4px solid #bf6800;
        border-bottom: 4px solid #ffe758;
        border-right: 4px solid #ffe758;
        box-shadow: 0 0px 1px 3px #00ffea;
        &:hover {
          box-shadow: 0 0px 1px 3px #00ffea;
        }
      }
    }
    &-btns {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: center;
      align-items: center;
    }
  }

  .oral-index {
    &__container {
      height: 100%;
      padding: 0rem 2rem 6rem 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      grid-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      width: 100%;
      grid-gap: 1.5rem;
      &--item {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        transition: all 0.2s;
        position: relative;
        height: 150px;
        overflow: hidden;
        &:hover {
          background: #fff;
          .oral-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        a {
          text-decoration: none;
        }
        &-img {
          width: 100%;
          &--container {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &-text {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: #4e4e54;
        }
      }
    }
    }
  }

  .num-words {
    font-size: 0.8em;
  }

  @media only screen and (max-width: 1560px) {
    .oral-index__container {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .oral-index {
      &__container {
        padding: 0rem 1.5rem 6rem 1.5rem;
        grid-gap: 1.25rem;
        &--item {
          padding: .25rem;
          &-icon {
            padding: .75rem;
            i {
              font-size: 3rem;
            }
          }
        }
        &--text {
          &-title {
            font-size: .9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .oral-index {
      &__container {
        background: none;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 690px) {
    .oral-index__container {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 580px) {
    .oral-index__container {
      grid-gap: 1rem;
    }
    .oral-index {
      &__container {
        padding: 0 .75rem 6rem .75em;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .oral-index__container--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .oral-index__container--text-title {
      font-size: .8rem;
    }
  }
</style>
