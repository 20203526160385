<template>
  <div class="ai-practice__component">
    <div class="ai-practice__component--header">
      <!-- <i class="fas fa-comments"></i> -->
      <router-link class="ai-practice__component--header--back"
        :to="{name: 'profile-main'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <h2 class="ai-practice__component--header--text">
        <i class="fas fa-comments"></i>
        Latihan Perbualan dengan AI
      </h2>
    </div>
    <div class="ai-practice__component--body">
      <router-link
        v-for="(entity, index) in entities"
        :key="index"
        :to="getRoute(entity)"
        class="ai-practice__component--body--item">
        <div class="user-settings-content">
          <div class="ai-practice__component--body--item-icon">
            <i class="fas icon-main fa-comments"></i>
          </div>
          <div class="ai-practice__component--body--text">
            <span class="ai-practice__component--body--text-title">
              {{ entity.title }}
            </span>
            <br>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';
import axios from 'axios';
import 'large-small-dynamic-viewport-units-polyfill';

export default {
  name: 'NewFreeResponseIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      entities: [],
      category: 'aiPractise',
    };
  },
  computed: {
    ...mapState(['level']),
    ...mapState('guidedReading', ['selectedLevel', 'passages', 'freeResponseTopics']),
    ...mapGetters(['chineseLevel', 'validLevels']),
  },
  mounted() {
    if (this.selectedLevel === '') {
      this.setSelectedLevel(this.level);
    }
    this.pullAiPractise();
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('guidedReading', ['setSelectedLevel', 'setPassages', 'setFreeResponseTopics', 'setFreeResponseQuestions']),
    ...mapActions(['resetState']),
    logout() {
      this.resetState();
      // redirects to login page
      this.$router.push({name: 'login-main'});
    },
    // for readaloud, we want to go directly to passage
    // for free response, we want to go to a subtopic page
    getRoute(entity) {
      return {
        name: 'new-oral-ai-practice',
        params: {
          questionId: entity.id,
          subject: entity.subject,
        },
      };
    },
    pullAiPractise() {
      if (!this.freeResponseTopics || this.freeResponseTopics.length === 0) {
        this.setShowGlobalLoadingScreen(true);
        axios
            .get('/vocab/oralfreeresponse/questions/')
            .then((response) => {
              this.setFreeResponseQuestions(response.data);
              this.entities = response.data;
              this.setShowGlobalLoadingScreen(false);
            });
      } else {
        this.entities = this.freeResponseTopics;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  .ai-practice {
    &__component {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      height: 100vh; /* For browsers that don't support CSS variables */
      height: calc((var(--1dvh, 1vh) * 100)); /* This is the "polyfill" */
      height: 100dvh; /* This is for future browsers that support svh, dvh and lvh viewport units */
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
      justify-content: flex-start;
      &--header {
        padding: 1rem;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #e7e7e7;
        width: 100%;
        &--text {
          flex: 1;
          text-align: center;
        }
        &--back {
          display: flex;
          height: 100%;
          align-items: center;
          margin: 0 1rem;
          font-size: 1.5rem;
          color: #555;
          text-decoration: none;
        }
        .fa-comments {
          margin-right: .75rem;
          font-size: 1.5rem;
        }
        .fa-right-from-bracket {
          margin-left: auto;
          font-size: 2rem;
          cursor: pointer;
        }
      }
      &--body {
        padding: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        width: 100%;
        grid-gap: 1.5rem;
        &--item {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          background: #fff;
          border-radius: 7.5px;
          text-decoration: none;
          border: 6px solid transparent;
          transition: all 0.2s;
          position: relative;
          background: #ebebeb;
          padding: 0 0 .25rem 0;
          &:hover {
            background: #8abcf2;
            .ai-practice__component--body--item-icon {
              background: #fff;
              border: 6px solid #c0deff;
              i {
                color: #2b92f8;
              }
            }
          }
          .user-settings-content {
            height: 100%;
            width: 100%;
          }
          a {
            text-decoration: none;
          }
          &-icon {
            height: 75%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 6px solid #e9e9e9;
            margin: .5rem .5rem;
            transition: all 0.2s;
            padding: 1rem;
            border-radius: 0.5rem;
            background: #fff;
            i {
              transition: all 0.2s;
              color: #aaa;
              font-size: 3.5rem;
              margin-bottom: 5px;
            }
          }
        }
        &--text {
          width: 100%;
          height: calc(25% - 1rem);
          display: flex;
          justify-content: center;
          align-items: center;
          &-title {
            font-weight: 600;
            font-size: 1rem;
            color: #4e4e54;
          }
        }
      }
    }
  }
  // .body-content {
  //   height: 100%;
  //   width: 100%;
  //   overflow: hidden;
  //   position: relative;
  // }
  // .oral-level {
  //   &-btn {
  //     border-bottom: 4px solid #193295;
  //     border-right: 4px solid #193295;
  //     border-top: 4px solid #5879fe;
  //     border-left: 4px solid #5879fe;
  //     color: #fff;
  //     background: #2c57ff;
  //     padding: .1rem;
  //     width: 13%;
  //     border-radius: 0.5rem;
  //     font-size: .9rem;
  //     margin-right: .5rem;
  //     text-align: center;
  //     cursor: pointer;
  //     &:last-child {
  //       margin-right: 0;
  //     }
  //     &:hover {
  //       box-shadow: 0 0px 1px 3px #ff00dd;
  //     }
  //     &--active {
  //       color: #fff;
  //       background: #ffa025;
  //       font-weight: 600;
  //       border-top: 4px solid #bf6800;
  //       border-left: 4px solid #bf6800;
  //       border-bottom: 4px solid #ffe758;
  //       border-right: 4px solid #ffe758;
  //       box-shadow: 0 0px 1px 3px #00ffea;
  //       &:hover {
  //         box-shadow: 0 0px 1px 3px #00ffea;
  //       }
  //     }
  //   }
  //   &-btns {
  //     display: flex;
  //     margin-top: 1rem;
  //     margin-bottom: 1rem;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }

  // .num-words {
  //   font-size: 0.8em;
  // }

  // @media only screen and (max-width: 1560px) {
  //   .oral-index__container {
  //     grid-template-columns: 1fr 1fr;
  //   }
  // }
  // @media only screen and (max-width: 1366px), screen and (max-height: 870px) {
  //   .oral-index {
  //     &__container {
  //       padding: 1.5rem;
  //       grid-gap: 1.25rem;
  //       &--padding {
  //         padding: 0rem 1.5rem 6rem 1.5rem;
  //       }
  //       &--item {
  //         padding: .25rem;
  //         &-icon {
  //           padding: .75rem;
  //           i {
  //             font-size: 3rem;
  //           }
  //         }
  //       }
  //       &--text {
  //         &-title {
  //           font-size: .9rem;
  //         }
  //       }
  //     }
  //   }
  // }

  // @media only screen and (max-width: 1150px),
  // screen and (max-height: 690px) {
  // }
  // @media only screen and (max-width: 960px),
  // screen and (max-height: 620px) {
  //   .oral-index {
  //     &__container {
  //       background: none;
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  // }
  // @media only screen and (max-width: 690px) {
  //   .oral-index__container {
  //     grid-template-columns: 1fr;
  //   }
  // }
  // @media only screen and (max-width: 580px) {
  //   .oral-index__container {
  //     grid-gap: 1rem;
  //   }
  // }
  // @media only screen and (max-width: 480px) {
  //   .oral-index__container--item-icon {
  //     padding: .5rem;
  //     i {
  //       font-size: 2.5rem;
  //     }
  //   }
  //   .oral-index__container--text-title {
  //     font-size: .8rem;
  //   }
  // }

</style>
