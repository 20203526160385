<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h4>Billing</h4>
      </div>
    </div>
    <div v-if="studentType === 'attending'" class="row">
      <div class="col md-12">
        Thank you for enrolling in our classes, and {{ studentName }} has complimentary access to VocabKing.
      </div>
    </div>
    <div v-if="studentType === 'digital_trial' || studentType === 'digital_subscription'" class="row">
      <div class="col-md-12 digital-subscription">
        <p>Student: <b>{{ studentName }}</b></p>
        <p>Current Plan: <b>{{ vocabSubscription.plan | splitCapitalise }}</b></p>
        <p>Expiry: <b>
          {{ vocabSubscription.end_date }} <span v-if="vocabSubscription.status === 'upcoming_cancellation'">(Will be cancelled)</span>
          </b>
          <i v-if="vocabSubscription.payment_mode === 'others'">(no auto-renewal)</i>
        </p>
        <div v-if="studentType === 'digital_subscription' && vocabSubscription.payment_mode === 'stripe'">
          <!-- placeholder for upgrading -->
          <button v-if="canUpgrade" @click="upgrade('diamond')" class="btn btn-success">Upgrade to Diamond (additional $100/yr)</button>
          <a :href="linkStripePortal">
            <button v-if="canCancel" @click="cancel" class="btn btn-warning">
              <span v-if="vocabSubscription.status !== 'upcoming_cancellation'">Cancel Subscription</span>
              <span v-else>Renew Subscription</span>
            </button>
          </a>
        </div>
        <div v-if="studentType === 'digital_trial'">
          <hr>
          <div v-if="vocabSubscription['eligible_for_sibling_discount']">
            <h5 >
              Enjoy a 30% Sibling Discount!
            </h5>
            <button @click="purchase('gold_sibling')" class="btn btn-success">
              Upgrade to Gold ($126)
            </button>
            <button @click="purchase('diamond_sibling')" class="btn btn-warning">
              Upgrade to Diamond ($196)
            </button>
          </div>
          <div v-else>
            <button @click="purchase('gold')" class="btn btn-success">Upgrade to Gold ($180)</button>
            <button @click="purchase('diamond')" class="btn btn-warning">Upgrade to Diamond ($280)</button>
          </div>
        </div>
      </div>
    </div>
    <!-- using bootstrap4 here -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import axios from 'axios';

export default {
  name: 'BillingIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      stripe: null,
      fetching: false,
    };
  },
  computed: {
    ...mapState(['vocabSubscription', 'studentType', 'studentName', 'parentEmail']),
    // for now, dont provide option for sibling upgrade diamond (because unsure how to handle)
    canUpgrade() {
      return (this.vocabSubscription.plan !== 'diamond' && this.vocabSubscription.plan !== 'diamond_sibling' && this.vocabSubscription.plan !== 'psle_success_plan') && !this.vocabSubscription.eligible_for_sibling_discount;
    },
    canCancel() {
      return ['gold', 'gold_sibling', 'diamond', 'diamond_sibling'].indexOf(this.vocabSubscription.plan) > -1;
    },
    linkStripePortal() {
      const query = new URLSearchParams({'prefilled_email': this.parentEmail}).toString();
      return 'https://billing.stripe.com/p/login/test_eVa5ns1WCg8Z9SU7ss?' + query;
    },
  },
  mounted: function() {
    axios.get('/api/v1/core/student/stripe_config/')
        .then((response) => {
          this.stripe = Stripe(response.data.publicKey);
          this.setVocabSubscription(response.data['vocab_subscription']);
          console.log(this.vocabSubscription);
        });
  },
  methods: {
    ...mapMutations(['setVocabSubscription']),
    cancel() {},
    purchase(plan) {
      if (this.fetching) return;
      this.fetching = true;
      axios.post('/api/v1/core/student/create_checkout_session/', {plan: plan})
          .then((response) => {
            return this.stripe.redirectToCheckout({
              sessionId: response.data.sessionId,
            });
          })
          .then((response) => {
            this.fetching = false;
          })
          .catch((error) => {
            this.fetching = false;
            alert(error.response.data.detail);
          });
    },
    upgrade(plan) {
      if (this.fetching) return;
      this.fetching = true;
      axios.post('/api/v1/core/student/upgrade/', {plan: plan})
          .then((response) => {
            console.log(response);
            // return this.stripe.redirectToCheckout({
            //   sessionId: response.data.sessionId,
            // });
          })
          .then((response) => {
            this.fetching = false;
          })
          .catch((error) => {
            this.fetching = false;
            alert(error.response.data.detail);
          });
    },
  },
};

</script>

<style lang="scss" scoped>
  .digital-subscription p {
    margin-bottom: 0;
  }
</style>
