<template>
  <div class="wordlist--container">
    <transition-group name="fade-slide" tag="ul" class="wordlist">
      <li v-for="word in words" :key="word" class="wordlist--word">
        {{ word[0] }}
        <span class="wordlist--word--points">
          {{ word[1] }}
        </span>
      </li>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'ObjectiveWordList',
  data() {
    return {
    };
  },
  props: {
    words: {
      type: Array,
      required: true,
    },
  },
  computed: {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
    .wordlist {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      margin: .5rem;
      &--container {
        display: flex;
        flex-direction: column;
        width:95%;
        background: #25418c;
        padding: .5rem;
        border-radius: .5rem;
      }
      &--word {
        position: relative;
        background: #fff;
        border-radius: .5rem;
        padding: 0.1rem 0.75rem;
        text-align: center;
        &--points {
          position: absolute;
          bottom: .1rem;
          right: .3rem;
          font-size: .6rem;
          font-weight: 500;
        }
      }
    }

  .fade-slide-enter-active,
  .fade-slide-leave-active {
    transition: all 0.5s ease;
  }

  .fade-slide-enter-from,
  .fade-slide-leave-to {
    opacity: 0;
    transform: translateY(20px) scale(0.8);
  }

  .fade-slide-move {
    transition: transform 0.5s ease;
  }
</style>
