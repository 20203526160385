<template>
  <div class="comprehension__component--container">
    <div class="comprehension__component--header">
      <div class="comprehension__component--header-btn-back"
        @click.prevent="endComprehension">
        <i class="fa-solid fa-angle-left"></i>
        <span>{{ this.getUIText('Back') }}</span>
      </div>
      <h2 class="comprehension__component--header-text">
        <i class="fa-solid fa-book-open-reader"></i>
        {{ this.getUIText('Comprehension') }}
      </h2>
    </div>
    <div class="comprehension__component--content">
      <div class="comprehension__component--content-text">
        <div class="comprehension__component--content-text--header">
          <i class="fa-solid fa-circle-exclamation"></i>
          <span v-if="uiLanguage === 'malay'">Baca dan jawab soalan.</span>
          <span v-else>Read the passage below before starting the quiz</span>
        </div>
        <!--
          we cannot use index as paraIndex since for lower primary, if we are only showing selected passages
          the paraIndex would change and it wouldnt match with sentencesToHighlight
        -->
        <div class="comprehension__component--content-text--body">
          <paragraph
            v-for="(info, index) in paragraphsToShow"
              :key="index"
              :para-index="info.paraIndex"
              :texts="info.texts">
          </paragraph>
        </div>
      </div>
      <div class="comprehension__component--content-interactive">
        <!-- Start Scene -->
        <div class="comprehension__component--content-interactive--start"
          v-if="comprehensionState === 0">
          <div class="comprehension__component--content-interactive--start-content">
            <h2 class="glossary--header">
              <i class="fa-solid fa-book"></i>
              {{ this.getUIText('Dictionary') }}
            </h2>
            <ul class="glossary--body">
              <li class="glossary--body-item"
                v-for="(info, index) in currentGlossary" :key="index"
                @click="playSound(info.src)">
                <!-- <i v-if="typeof info.src !== 'undefined'"
                  class="fas fa-volume-up audio-icon"></i> -->
                <span class="glossary--body-item--text">
                  {{ info.text }}
                </span>
                <span class="glossary--body-item--definition">
                  {{ info.definition }}
                </span>
              </li>
            </ul>
          </div>
          <button
            class="comprehension__component--content-interactive--start-btn"
            @click="startQuiz">
            {{ this.getUIText('Start') }}
          </button>
        </div>
        <!-- Qns Scene -->
        <div v-if="comprehensionState !== 0 && !selectedEntity && !showDictionaryTooltip"
          class="comprehension__component--content-interactive--qns">
          <question :info="currentQuestion"></question>
          <button
            :class="submitButtonClass"
            class="comprehension__component--content-interactive--qns-btn"
            @click="next">
            <span v-if="comprehensionState === 1">{{ this.getUIText('Check') }}</span>
            <span v-if="comprehensionState === 2">{{ this.getUIText('Next') }}</span>
            <span v-if="comprehensionState === 3">{{ this.getUIText('Finish') }}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- results['diamonds_to_add'] -->
    <basic-reward v-if="showRewardsPage"
      :diamonds-to-add="3"
      :close-rewards-callback="onCloseRewards">
    </basic-reward>
  </div>
</template>

<script>

import {mapState, mapMutations, mapGetters} from 'vuex';

// diamonds to add is hardcoded for now

import ParagraphComponentComponent
  from './components/ParagraphComponent.vue';

import QuestionComponentComponent
  from './components/QuestionComponent.vue';

import BasicRewardComponent
  from '../../../common/BasicRewardComponent.vue';

export default {
  name: 'NewMalayComprehensionPassage',
  components: {
    Paragraph: ParagraphComponentComponent,
    Question: QuestionComponentComponent,
    BasicReward: BasicRewardComponent,
    // TeachingScreen: TeachingScreenComponent,
  },
  props: {
    comprehensionId: Number,
  },
  watch: {
  },
  data() {
    return {
      uiLanguage: 'malay',
      showRewardsPage: false,
      // should remove
      showDictionaryTooltip: false,
    };
  },
  computed: {
    ...mapState('guidedReading', ['selectedEntity', 'comprehensions', 'comprehensionState', 'questionIndex', 'questions', 'selectedAnswer']),
    ...mapGetters('guidedReading', ['currentComprehension', 'currentQuestions', 'currentQuestion', 'currentGlossary']),
    submitButtonClass() {
      return this.comprehensionState === 1 && this.selectedAnswer === '' ? 'disabled' : '';
    },
    // for lower primary, for specific questions, we show directly the paragraphs it comes from
    paragraphsToShow() {
      const toReturn = [];
      if (this.currentComprehension === null) return toReturn;
      if (this.comprehensionState === 0 || !this.currentQuestion || typeof this.currentQuestion.paragraphs_to_show === 'undefined') {
        for (let i = 0; i < this.currentComprehension.paragraphs.length; i++) {
          const toAdd = {
            'texts': this.currentComprehension.paragraphs[i],
            'paraIndex': i,
          };
          toReturn.push(toAdd);
        }
        return toReturn;
      }
      // if paragraphs_to_show is valid, show only the paragraps that are relevant to specific question
      for (const paraIndex of this.currentQuestion.paragraphs_to_show) {
        const toAdd = {};
        toAdd.texts = this.currentComprehension.paragraphs[paraIndex];
        toAdd.paraIndex = paraIndex;
        toReturn.push(toAdd);
      }
      return toReturn;
    },
  },
  mounted() {
    if (this.comprehensions.length === 0) {
      this.$router.push({name: 'new-malay-comprehension-overview'});
    }
    this.setComprehensionId(this.comprehensionId);
    this.setComprehensionState(0);
    // this.setQuestions(this.currentComprehension.questions);
  },
  methods: {
    ...mapMutations('guidedReading', ['setComprehensionId',
      'setComprehensionState', 'setQuestions', 'setQuestionIndex', 'setSelectedAnswer']),
    getUIText(text) {
      if (this.uiLanguage === 'english') return text;
      text = text.toLowerCase();
      const subjectToTextsMap = {
        'malay': {
          'check': 'Hantar',
          'start': 'Bermula',
          'next': 'Seterusnya',
          'finish': 'Berakhir',
          'question': 'Soalan',
          'glossary': 'Glosari',
          'dictionary': 'Kamus',
          'comprehension': 'Kefahaman',
          'back': 'Kembali',
        },
      };
      return typeof subjectToTextsMap[this.uiLanguage][text] === 'undefined' ?
        '' : subjectToTextsMap[this.uiLanguage][text];
    },
    playSound(src) {
      // cancel for now as we didnt record audio for glossary
      return false;
      const p = new Promise((resolve, reject) => {
        const sound = new Howl({
          src: [src],
          onload: () => {
            resolve(sound);
          },
        });
      });

      p.then((sound) => {
        sound.play();
      });
    },
    onCloseRewards() {
      // set coins and diamonds
      // this.setCoins(this.newCoins); // no coins
      // this.setDiamonds(this.results.diamonds);
      this.showRewardsPage = false;
      this.$router.push({name: 'new-malay-comprehension-overview'});
    },
    startQuiz() {
      // change comprehension state and show questions
      this.setSelectedAnswer('');
      this.setComprehensionState(1);
    },
    next() {
      // 1 - show question
      // 2 - student has clicked answer (show correct answer and where it came from)

      // checks that child has selected a choice
      if (this.comprehensionState === 1) {
        // don't proceed until child has selected an answer
        if (this.selectedAnswer === '') return;
        this.setComprehensionState(2);
        return;
      }

      // check if we have reached max question before deciding if we can show next question
      if (this.questionIndex + 1 >= this.currentQuestions.length) {
        // ended
        this.setComprehensionState(3);
        this.showRewardsPage = true;
      } else {
        // go to next question
        this.setQuestionIndex(this.questionIndex + 1);
        this.setSelectedAnswer('');
        this.setComprehensionState(1);
      }
    },
    endComprehension() {
      this.$router.push({name: 'new-malay-comprehension-overview'});
    },
  },
};
</script>

<style lang="scss" scoped>
  .disabled {
    // background: red !important;
  }

  .close {
    position: absolute;
    left: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: '';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  .comprehension {
    &__component {
      &--container {
        font-size: 120%;
        font-family: mulish, century-gothic;
        z-index: 100;
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #E1E1E1;
      }
      &--header {
        display: flex;
        justify-content: center;
        background: linear-gradient(to right, #3530a6, #1E5EA6);
        border-bottom: 8px solid #1C3E88;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        width: 100%;
        color: #fff;
        position: absolute;
        top: 0;
        &-btn-back {
          position: absolute;
          left: 1.25rem;
          top: .3rem;
          font-size: 1.35rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          i {
            margin-right: 0.25rem;
          }
        }
        &-text{
        }
      }
      &--content {
        margin-top: 60px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 2rem;
        grid-gap: 1rem;
        height: calc(100% - 60px);
        &-text, &-interactive {
          width: 50%;
          height: 100%;
        }
        &-text {
          background: #fff;
          position: relative;
          overflow: hidden;
          border-radius: .25rem;
          &--header {
            font-weight: 600;
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            background: #2B456A;
            padding: .5rem;
            text-align: center;
            width: 100%;
            overflow: hidden;
            border-top-left-radius: .5rem;
            i {
              margin-right: .5rem;
              font-size: 1.2rem;
            }
          }
          &--body {
            overflow-y: scroll;
            height: calc(100% - 54px);
            margin-top: 54px;
            padding: 2rem 3rem 1rem 3rem;
            &::-webkit-scrollbar {
              width: 0.75rem;
            }
            &::-webkit-scrollbar-track {
              background: #ebebeb;
            }
            &::-webkit-scrollbar-thumb {
              background: linear-gradient(45deg, orange, darksalmon);
            }
          }
        }
        &-interactive {
          &--start {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-content {
              height: calc(100% - 48px);
              margin-bottom: .75rem;
              padding: 3rem;
              border-radius: .25rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              background: #fff;
              .glossary {
                &--header {
                  margin-bottom: 1.5rem;
                  width: 100%;
                  i {
                    margin-right: .5rem;
                  }
                }
                &--body {
                  width: 100%;
                  display: grid;
                  grid-gap: 1rem;
                  grid-template-columns: 1fr 1fr 1fr;
                  &-item {
                    position: relative;
                    padding: 1.5rem 0rem;
                    border-radius: .5rem;
                    border: 4px solid #000;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    &:hover {
                      background: #fff0bf;
                    }
                    .audio-icon {
                      position: absolute;
                      right: .5rem;
                      top: .5rem;
                      font-size: .9rem;
                    }
                    &--text {
                      font-size: 1.35rem;
                      font-weight: 700;
                      line-height: 1.8rem;
                    }
                    &--definition {
                      font-size: .9rem;
                      font-style: italic;
                      text-align: center;
                    }
                  }
                }
              }
            }
            &-btn {
              background: none;
              border: none;
              outline: none;
              box-shadow: none;
              background: #316cac;
              color: #fff;
              font-size: 1.3rem;
              font-weight: 600;
              padding: .5rem 1rem;
              border-radius: .25rem;
              font-family: mulish, century-gothic;
              cursor: pointer;
              &:hover {
                background: #3f80c5;
              }
            }
          }
          &--qns {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-btn {
              border: none;
              outline: none;
              box-shadow: none;
              background: #319eac;
              color: #fff;
              font-size: 1.3rem;
              font-weight: 600;
              padding: .5rem 1rem;
              border-radius: .25rem;
              font-family: mulish, century-gothic;
              cursor: pointer;
              &:hover {
                background: #3cb4c4;
              }
            }
          }
          .disabled {
            background: #b5b5b5;
            color: #cbcbcb;
            cursor: auto;
          }
        }
      }
    }
  }

  .paragraph {
    padding-bottom: 30px;
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    line-height: 150%;
    text-indent: 3rem;
    text-align: justify;
  }

</style>
