<template>
  <block-container
    title="Play"
    fa-icon="gamepad"
    >
    <div class="body-content">
      <!-- only primary school students can change difficulty -->
      <a :href="cocosPetBattleUrl" target="_blank"
        class="body-content--item">
        <div class="body-content--item--title">
          Pet Battle
        </div>
        <div class="body-content--item-icon bg-petbattle">
        </div>
        <div class="body-content--item-info">
          <div class="body-content--item-info--tags">
            <!-- <span class="vocab">词语</span>
            <span class="assessment">语文应用</span> -->
          </div>
          <!-- <div class="body-content--item-info--credits">
            <i class="fa-solid fa-heart"></i>
            <span>15</span>
          </div> -->
        </div>
      </a>
      <router-link
        v-if="showFishingGame"
        :to="{name: 'new-play-fishing-game'}"
        tag="p"
        class="body-content--item">
        <div class="body-content--item--title">
          Fishing Game
        </div>
        <div class="body-content--item-icon bg-fishing">
        </div>
        <div class="body-content--item-info">
          <div class="body-content--item-info--tags">
            <!-- <span class="oral">口试</span> -->
          </div>
          <!-- <div class="body-content--item-info--credits">
            <i class="fa-solid fa-heart"></i>
            <span>15</span>
          </div> -->
        </div>
      </router-link>
      <!-- tree game and fishing game share similar logic - only available on primary school -->
      <router-link
        v-if="subject === 'chinese' && showFishingGame"
        :to="{name: 'new-play-tree-climb'}"
        tag="p"
        class="body-content--item">
        <div class="body-content--item--title">
          Tree Climb
        </div>
        <div class="body-content--item-icon bg-tree">
        </div>
        <div class="body-content--item-info">
          <div class="body-content--item-info--tags">
            <!-- <span class="zucichengju">词语搭配</span>
            <span class="bianzi">辨字</span> -->
          </div>
          <!-- <div class="body-content--item-info--credits">
            <i class="fa-solid fa-heart"></i>
            <span>3</span>
          </div> -->
        </div>
      </router-link>
      <router-link
        :to="{name: 'new-play-avatar'}"
        tag="p"
        class="body-content--item">
        <div class="body-content--item--title">
          Change Avatar
        </div>
        <div class="body-content--item-icon bg-avatar">
        </div>
      </router-link>
    </div>
  </block-container>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
// import {mapState, mapGetters, mapActions} from 'vuex';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

export default {
  name: 'NewPlayOverview',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['level', 'subject']),
    ...mapGetters(['cocosPetBattleUrl']),
    showFishingGame() {
      return ['primary_one', 'primary_two', 'primary_three', 'primary_four', 'primary_five', 'primary_six'].indexOf(this.level) > -1;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    gap: 1rem;
    &--item {
      background: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      text-align: center;
      border-radius: .25rem;
      text-decoration: none;
      transition: all 0.2s;
      position: relative;
      height: 250px;
      padding-top: .15rem;
      overflow: hidden;
      &:hover {
        box-shadow: 0 0px 1px 7px #00ffd8;
      }
      &--title {
        color: #6b6b6b;
        text-align: left;
        font-weight: 600;
        margin-left: .5rem;
      }
      &-icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        background-size: cover;
        background-position: center;
        i {
          transition: all 0.2s;
          color: #b9b6c1;
          font-size: 3.5rem;
          margin-bottom: 5px;
        }
      }
      &-info {
        background: #fff;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        color: #6b6b6b;
        padding-top: .5rem;
        position: relative;
        justify-content: space-between;
        padding: .25rem .5rem;
        &--tags {
          color: #fff;
          font-weight: 800;
          font-size: .7rem;
          .vocab {
            background: #e98a24;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .assessment {
            background: #04cb34;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .zucichengju {
            background: #6f4fff;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .bianzi {
            background: #2bb5d8;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .oral {
            background: #ff4f8f;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
        }
        &--credits {
          font-size: .9rem;
          i {
            margin-right: .25rem;
          }
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .bg-avatar {
    background-image: url("../../../assets/profile/btn-avatar.jpg");
  }
  .bg-petbattle {
    background-image: url("../../../assets/profile/btn-petbattle.jpg");
  }

  .bg-fishing {
    background-image: url("../../../assets/profile/btn-fishing.jpg");
  }
  .bg-tree {
    background-image: url("../../../assets/profile/btn-tree.jpg");
  }


  @media only screen and (max-width: 1560px) {
    .body-content {
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .body-content {
      grid-template-columns: 1fr 1fr;
      &--item {
        height: 100%;
      }
      &-text {
        font-size: .9rem;
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .body-content {
      background: none;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 690px) {
    .body-content {
      padding: 1.5rem
    }
  }
  @media only screen and (max-width: 580px) {
    // .body-content {
    //   grid-template-columns: 1fr;
    // }
  }
  @media only screen and (max-width: 480px) {
    .body-content {
      padding: .75rem;
      gap: .75rem;
    }
    .body-content--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .body-content--item-text {
      font-size: .8rem;
    }
  }
  @media only screen and (max-width: 430px) {
    .body-content--item {
      flex-direction: column-reverse;
      padding-top: 0;
      &-info {
        display: none;
      }
      &-icon {
        border: 6px solid #fff;
        border-radius: .5rem;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .body-content--item-info {
      padding: .25rem;
    }
  }
</style>
