<template>
  <div class="main-profile__component">
    <div
      v-if='!fetchingInitialData'
      class="profile__container">
      <div class="profile__container--content">
        <div class="profile__container--content-row-1">
          <user-level-progressbar :num-known-words="totalKnownWords"
          class="animated bounceIn"></user-level-progressbar>
          <div class="profile__container--content-row-1--btns">
            <a :href="cocosPetBattleUrl" target="_blank" class="animated bounceIn delay-1">
              <div class="pet-battle">
                <div class="pet-battle--bg-gradient"></div>
                <div class="pet-battle--text">
                  <h2 class="pet-battle--text-main">
                    Pet Battle
                  </h2>
                  <p class="pet-battle--text-sub">
                    Have what it takes to win? Join a Pet Battle now!
                  </p>
                </div>
              </div>
            </a>
            <router-link
              :to="{name: 'new-play-fishing-game'}"
              tag="p"
              class="fishing-game animated bounceIn delay-2">
              <div class="fishing-game--bg-gradient"></div>
                <div class="fishing-game--text">
                  <h2 class="fishing-game--text-main">
                    Fishing Game
                  </h2>
                  <p class="fishing-game--text-sub">
                    Become a grandmaster fisherman! Try it now!
                  </p>
                </div>
            </router-link>
          </div>
          <!-- for now, cannot use component as external a tag -->
        </div>
        <div class="profile__container--content-row-2">
          <profile-page-button
            type="dailyChallenge"
            title="Daily Challenge"
            subtitle="Help repair the Orb of Knowledge! Take a Quiz now!"
            router-link-name="daily-challenge-overview"
            class="animated bounceIn delay-2"
          ></profile-page-button>
          <!-- malay oral -->
          <!-- v-if="subject === 'malay'" -->
          <profile-page-button
            v-if="subject === 'malay'"
            type="malaySpeaking"
            title="Speech King"
            subtitle="Practise Speaking!"
            router-link-name="new-oral-voicebot-index"
            class="animated bounceIn delay-2"
          ></profile-page-button>

          <!-- for preschool, go to library; for P1-P4, show latest video lesson, for P5-P6, show compo king -->
          <div class="misc-carousel animated bounceIn delay-3">
            <profile-page-button
              v-if="subject === 'chinese' && isPreschool"
              type="stories"
              title="Story Lessons"
              subtitle="Watch the latest story!"
              router-link-name="library-overview"
            ></profile-page-button>
            <!-- video-lesson-compo-main-latest -->
            <profile-page-button
              v-if="subject === 'chinese' && !isPreschool && showCompoKing"
              type="videoLessons"
              title="Compo King"
              subtitle="Practise Compo!"
              router-link-name="new-composition-overview"
            ></profile-page-button>
            <!-- need to fix subtitle; should by dynamic -->
            <profile-page-button
              v-if="subject === 'chinese' && !isPreschool && !showCompoKing"
              type="videoLessons"
              title="Video Lessons"
              subtitle="Latest Video lesson!"
              router-link-name="video-lesson-main-latest"
            ></profile-page-button>
          </div>
        </div>
      </div>
    </div>
    <narrator-popup
      v-if="showOnboardingPopup"
      :dialogue-data="dialogueData"
      :end-callback="onCloseOnboardingPopup">
    </narrator-popup>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations, mapGetters} from 'vuex';
// https://www.npmjs.com/package/large-small-dynamic-viewport-units-polyfill
import 'large-small-dynamic-viewport-units-polyfill';

// start profile components
import UserLevelProgressbarComponent
  from './components/UserLevelProgressbarComponent.vue';

import ProfilePageButtonComponent
  from './components/ProfilePageButtonComponent.vue';

import NarratorPopupComponent
  from '../../common/NarratorPopupComponent.vue';

export default {
  name: 'ProfileMain',
  components: {
    UserLevelProgressbar: UserLevelProgressbarComponent,
    ProfilePageButton: ProfilePageButtonComponent,
    NarratorPopup: NarratorPopupComponent,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['fetchingInitialData', 'totalKnownWords', 'level', 'studentName', 'subject']),
    ...mapGetters(['isPreschool', 'cocosPetBattleUrl']),
    dialogueData() {
      return [
        {
          narrator: 'flamebun',
          texts: ['Welcome to Boshi Land', 'Boshi Land is inhabited by cute pets like me that you can befriend.'],
        },
        {
          narrator: 'mangsha',
          texts: ['Work with your pets to complete missions to gain diamonds, and exchange them for rewards like Roblox vouchers!'],
        },
        {
          narrator: 'rakoon',
          texts: ['A world of dreams and adventures is just ahead! Let\'s go!'],
        },
      ];
    },
    // we don't have video lesson for P5-P6, so show compo king
    showCompoKing() {
      return this.level === 'primary_five' || this.level === 'primary_six';
    },
    showOnboardingPopup() {
      return !this.fetchingInitialData && !this.hasUsedFeature()('onboarding_popup_main_page');
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['addToUsedFeatures']),
    ...mapGetters(['hasUsedFeature']),
    onCloseOnboardingPopup() {
      axios
          .post('/api/v1/core/student/used_features/', {
            featureName: 'onboarding_popup_main_page',
          });
      // this will automatically hide the popup
      this.addToUsedFeatures('onboarding_popup_main_page');
      this.$router.push({name: 'new-missions-overview'});
    },
  },
};
</script>


<style lang="scss" scoped>
  .delay-1 {
    animation-delay: 0.1s;
  }
  .delay-2 {
    animation-delay: 0.2s;
  }
  .delay-3 {
    animation-delay: 0.3s;
  }

  a {
    text-decoration: none;
  }
  .main-profile__component {
    width: calc(100% - 337px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &::-webkit-scrollbar {
      display: none;
    }
    flex: 1;
    height: 100vh; /* For browsers that don't support CSS variables */
    height: calc(var(--1dvh, 1vh) * 100); /* This is the "polyfill" */
    height: 100dvh; /* This is for future browsers that support svh, dvh and lvh viewport units */
    color: #000;
  }
  .profile__container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &--content {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-row {
        &-1,
        &-2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .pet-battle,
          .fishing-game {
            &:hover {
              transform: scale(1.02);
            }
            transition: 0.2s all linear;
            cursor: pointer;
            position: relative;
            margin-left: 2rem;
            background-position: center;
            background-size: cover;
            border-radius: .75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 497px;
            height: 200px;
            &--bg-gradient {
              width: 100%;
              position: absolute;
              bottom: 0;
              z-index: 1;
              height: 60%;
              border-radius: 0.75rem;
              overflow: hidden;
              background: linear-gradient(to top, #c200ffb0, transparent)
            }
            &--text {
              z-index: 2;
              border: 4px solid #00000024;
              border-radius: 0.75rem;
              height: 95%;
              width: 95%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-direction: column;
              color: #fff;
              &-main {
                font-size: 1.75rem;
                margin-bottom: -0.75rem;
                text-shadow: 0px 4px rgb(0 0 0 / 60%);
              }
              &-sub {
                font-size: 1rem;
                margin-bottom: 0.25rem;
                text-shadow: 2px 2px rgb(0 0 0 / 60%);
              }
            }
          }
          .fishing-game {
            background-image: url("../../../assets/profile/btn-fishing.jpg");
            &--bg-gradient {
              background: linear-gradient(to top, rgb(0 125 255 / 69%), transparent);
            }
          }
          .pet-battle {
            background-image: url("../../../assets/profile/btn-petbattle.jpg");
          }
        }
        &-1 {
          margin-bottom: 2rem;
          &--btns {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1560px), screen and (max-height: 800px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 425px;
      margin-left: 1.5rem;
      &--text {
        &-main {
          font-size: 1.5rem;
        }
        &-sub {
          font-size: .85rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1366px), screen and (max-height: 690px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 375px;
      height: 180px;
      margin-left: 1rem;
      &--text {
        &-main {
          font-size: 1.35rem;
        }
        &-sub {
          font-size: .8rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1366px), screen and (max-height: 800px) {
    .main-profile__component {
      width: calc(100% - 275px);
    }
  }
  @media only screen and (max-width: 1150px), screen and (max-height: 690px) {
    .main-profile__component {
      width: calc(100% - 225px);
    }
  }
  @media only screen and (max-width: 960px), screen and (max-height: 620px) {
    .main-profile__component {
      // https://www.npmjs.com/package/large-small-dynamic-viewport-units-polyfill
      width: 100%;
      height: calc(100vh - 105px); /* For browsers that don't support CSS variables */
      height: calc((var(--1dvh, 1vh) * 100) - 105px); /* This is the "polyfill" */
      height: calc(100dvh - 105px); /* This is for future browsers that support svh, dvh and lvh viewport units */
      margin-top: 50px;
    }
  }

  @media only screen and (max-width: 1080px), screen and (max-height: 620px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 335px;
      margin-left: .75rem;
      &--text {
        &-main {
          font-size: 1.25rem;
        }
        &-sub {
          font-size: .65rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1010px), screen and (max-height: 620px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 300px;
      height: 160px;
    }
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game,
    .profile__container--content-row-2  {
      margin-left: .5rem;
      &--text {
        &-main {
          font-size: 1.2rem;
        }
        &-sub {
          font-size: .6rem;
          margin-top: 0.25rem;
        }
      }
    }
  }
  @media only screen and (max-width: 960px), screen and (max-height: 620px) {
    .misc-carousel {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .user-level-progressbar {
      margin-bottom: 1rem;
    }
    .profile__container {
      align-items: flex-start;
      height: calc(100% - 50px);
      margin-top: 50px;
      width: 100%;
      text-align: left;
      top: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      &--content {
        margin-top: 1rem;
        width: 90%;
        &-row-1 {
          width: 100%;
          display: flex;
          margin-bottom: 1rem;
          flex-direction: column;
          &--btns {
            width: 100%;
          }
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          .pet-battle {
            &--bg-gradient {
              height: 100%;
              background: linear-gradient(to top, rgba(194, 0, 255, 0.6901960784), #604bd2c4);
            }
            width: 95%;
            height: 120px;
            margin: auto;
            &--text {
              justify-content: center;
              &-main {
                font-size: 1.7rem;
              }
              &-sub {
                font-size: 1rem;
                display: none;
              }
            }
          }
          .fishing-game {
            &--bg-gradient {
              height: 100%;
              background: linear-gradient(to top, rgba(0, 125, 255, 0.69), #3989f3c4);
            }
            width: 95%;
            height: 120px;
            margin: auto;
            &--text {
              justify-content: center;
              &-main {
                font-size: 1.7rem;
              }
              &-sub {
                font-size: 1rem;
                display: none;
              }
            }
          }

        }
        &-row-2 {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 505px), screen and (max-height: 620px) {
    .profile__container--content {
      &-row-2 {
        .pet-battle--mobile {
          height: 180px;
        }
        .pet-battle {
          &--text {
            &-main {
              font-size: 1.5rem;
            }
            &-sub {
              font-size: .75rem;
            }
          }
        }

      }
    }
  }
</style>
