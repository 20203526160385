<template>
  <div id="active-vocab-container">
    <quiz-main
      quiz-type="malayVocab"
      :quiz-id="quizId"
    ></quiz-main>
  </div>
</template>

<script>
import QuizMainComponent from '../../common/quiz/QuizMainComponent.vue';

export default {
  name: 'NewMalayVocabQuiz',
  components: {
    QuizMain: QuizMainComponent,
  },
  props: {
    quizId: Number,
  },
  data() {
    return {};
  },
  mounted() {
  },
  computed: {
  },
};

</script>

<style lang="scss" scoped>
  #active-vocab-container {
    margin: 0 auto;
    height: 100%;
  }
</style>
