<template>
  <div class="dailymissions">
    <div class="dailymissions-container">
      <div class="dailymissions-header">
        <h2 class="dailymissions-header-main">
          Daily Missions
        </h2>
        <router-link class="dailymissions-header-sub"
          :to="{name: 'new-missions-overview'}">
          View All
        </router-link>
      </div>
      <ul class="dailymissions-content">
        <mission-row
          v-for="(info, index) in sortedMissions.daily"
          :key="index"
          :info="info"
        ></mission-row>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

import MissionRowComponent
  from './WidgetMissionRowComponent.vue';

export default {
  name: 'Missions',
  components: {
    MissionRow: MissionRowComponent,
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['missions']),
    ...mapGetters(['sortedMissions']),
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .dailymissions {
    background: #132647;
    color: #fff;
    margin: .75rem 1rem .75rem 0;
    padding: .25rem;
    border-radius: 0.5rem;
    &-container {
      padding: .25rem .5rem;
      border: 5px solid #1D335C;
      border-radius: 0.5rem;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-main {
        font-size: 1rem;
      }
      &-sub {
        font-size: 0.75rem;
        text-decoration: none;
        &:hover {
          color: #fff;
        }
      }
    }
    &-content {
      margin: .25rem;
      .mission-left {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .dailymissions {
      margin: .5rem .5rem 0.5rem 0;
      &-header {
        &-main {
          font-size: 0.9rem;
        }
        &-sub {
          font-size: 0.7rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
              screen and (max-height: 690px) {
    .dailymissions {
      margin: .5rem .25rem 0.25rem 0;
      &-container {
        padding: .25rem;
      }
      &-header {
        &-main {
          font-size: 0.75rem;
        }
        &-sub {
          font-size: 0.55rem;
        }
      }
      &-content {
        margin-top: .5rem;
      }
    }
  }

</style>
