import Vue from 'vue';

// used for both comprehension and oral
const getDefaultState = () => {
  return {
    wordlistIds: {},

    // for malay vocab
    malayChapters: {},

    // comprehension data
    entityData: [],
    comprehensions: [],
    comprehensionId: -1,
    // word, phrase
    selectedEntity: null,

    // only for multiple choice comprehension?
    // 0 - initial when student hasn't started (i.e. show full passage for lower primary)
    // 1 - show question
    // 2 - student has clicked answer (show correct answer and where it came from)
    comprehensionState: 0,
    questions: [],
    questionIndex: 0,
    selectedAnswer: '',
    // end comprehension

    // oral data
    selectedLevel: '', // for textbook
    passages: [],
    passageId: -1,
    freeResponseTopics: [],
    freeResponseQuestions: [],
    templatedPhrases: [],
    textbookChapters: [],
    textbookChapterId: -1,
    activeVocabTopics: [],
  };
};

const state = getDefaultState();

export const guidedReadingModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },

    // for malay vocab
    setMalayChapters(state, payload) {
      const {level, chapters} = payload;
      Vue.set(state['malayChapters'], level, chapters);
    },

    // start comprehensions
    setComprehensions(state, value) {
      Vue.set(state, 'comprehensions', value);
    },
    setComprehensionId(state, value) {
      Vue.set(state, 'comprehensionId', value);
    },
    setEntityData(state, value) {
      Vue.set(state, 'entityData', value);
    },
    setSelectedEntity(state, value) {
      state.selectedEntity = value;
    },

    // start multiple choice comprehension
    setComprehensionState(state, value) {
      state.comprehensionState = value;
    },
    setQuestionIndex(state, value) {
      state.questionIndex = value;
    },
    setQuestions(state, value) {
      Vue.set(state, 'questions', value);
    },
    setSelectedAnswer(state, value) {
      state.selectedAnswer = value;
    },
    // end multiple choice comprehension

    // for textbook
    setSelectedLevel(state, value) {
      Vue.set(state, 'selectedLevel', value);
    },
    // start passages
    setPassages(state, value) {
      Vue.set(state, 'passages', value);
    },
    setPassageId(state, value) {
      Vue.set(state, 'passageId', value);
    },
    // currently only stores some title and i dhere
    setFreeResponseTopics(state, value) {
      Vue.set(state, 'freeResponseTopics', value);
    },
    setTemplatedPhrasesTopics(state, value) {
      Vue.set(state, 'templatedPhrases', value);
    },
    // for ai practice
    setFreeResponseQuestions(state, value) {
      Vue.set(state, 'freeResponseQuestions', value);
    },
    // when child does an ai practise session for a qn
    incrementFreeResponseQuestionAttempts(state, questionId) {
      const index = state.freeResponseQuestions.findIndex((qn) => qn.id === questionId);
      if (index === -1) return;
      Vue.set(state.freeResponseQuestions, index, state.freeResponseQuestions[index] + 1);
    },
    // end ai practice
    setTextbookChapters(state, value) {
      Vue.set(state, 'textbookChapters', value);
    },
    setTextbookChapterId(state, value) {
      Vue.set(state, 'textbookChapterId', value);
    },
    setActiveVocabTopics(state, value) {
      Vue.set(state, 'activeVocabTopics', value);
    },
  },
  getters: {
    currentComprehension(state) {
      if (!state.comprehensions || state.comprehensions.length === 0) return null;
      return state.comprehensions.find((c) => c.id === state.comprehensionId) || null;
    },
    currentQuestions(state, getters) {
      if (!getters.currentComprehension) return [];
      return getters.currentComprehension.questions;
    },
    currentQuestion(state, getters) {
      if (!getters.currentQuestions) return null;
      return getters.currentQuestions[state.questionIndex];
    },
    currentGlossary(state, getters) {
      if (!getters.currentComprehension) return [];
      return getters.currentComprehension.glossary;
    },
  },
};
