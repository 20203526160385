import Vue from 'vue';

// used for both comprehension and oral
const getDefaultState = () => {
  return {
    isExceedDailyAudioLimit: false,
    selectedTopicId: null,
    topics: [],
  };
};

const state = getDefaultState();

export const voiceBotModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setIsExceedDailyAudioLimit(state, value) {
      Vue.set(state, 'isExceedDailyAudioLimit', value);
    },
    setSelectedTopicId(state, value) {
      Vue.set(state, 'selectedTopicId', value);
    },
    setTopics(state, value) {
      Vue.set(state, 'topics', value);
    },
  },
  getters: {
    selectedTopic: (state) => {
      if (!state.selectedTopicId) return null;
      return state.topics.find(topic => topic.id === state.selectedTopicId) || null;
    },
  },
};
