<template>
  <block-container
    title="Billing"
    fa-icon="cog"
    previous-page-name="new-settings-overview"
    >
    <div class="billing__component--form">
      <p>Student: <b>{{ studentName }}</b></p>
      <p>Current Plan: <b>{{ vocabSubscription.plan | splitCapitalise }}</b></p>
      <p>Expiry: <b>
        {{ vocabSubscription.end_date }} <span v-if="vocabSubscription.status === 'upcoming_cancellation'">(Will be cancelled)</span>
        </b>
        <i v-if="vocabSubscription.payment_mode === 'others'">(no auto-renewal)</i>
      </p>
      <div v-if="studentType === 'digital_subscription' && vocabSubscription.payment_mode === 'stripe'">
        <!-- placeholder for upgrading change to diamond+ in future-->
<!--         <button v-if="canUpgrade" @click="upgrade('diamond')" class="btn btn-success">Upgrade to Diamond (additional $100/yr)</button> -->
        <a :href="linkStripePortal">
          <button v-if="canCancel" @click="cancel" class="btn btn-warning">
            <span v-if="vocabSubscription.status !== 'upcoming_cancellation'">Cancel Subscription</span>
            <span v-else>Renew Subscription</span>
          </button>
        </a>
      </div>

      <div v-if="studentType === 'digital_trial'">
        <hr>
        <div v-if="vocabSubscription['eligible_for_sibling_discount']">
          <h5 >
            Enjoy a 30% Sibling Discount!
          </h5>
          <button @click="purchase('diamond_sibling')" class="btn btn-warning">
            Upgrade to Diamond ($196)
          </button>
        </div>
        <div v-else>
          <button @click="purchase('diamond')" class="btn btn-warning">Upgrade to Diamond ($280)</button>
        </div>
      </div>
    </div>
  </block-container>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import axios from 'axios';

import BlockContainerComponent
  from '../../common/BlockContainerComponent.vue';

export default {
  name: 'NewSettingsBillingIndex',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      stripe: null,
      fetching: false,
    };
  },
  computed: {
    ...mapState(['studentName', 'studentType', 'vocabSubscription', 'parentEmail']),
    canCancel() {
      return ['gold', 'gold_sibling', 'diamond', 'diamond_sibling', 'psle_success_plan'].indexOf(this.vocabSubscription.plan) > -1;
    },
    linkStripePortal() {
      const query = new URLSearchParams({'prefilled_email': this.parentEmail}).toString();
      return 'https://billing.stripe.com/p/login/test_eVa5ns1WCg8Z9SU7ss?' + query;
    },
  },
  mounted() {
    axios.get('/api/v1/core/student/stripe_config/')
        .then((response) => {
          this.stripe = Stripe(response.data.publicKey);
          this.setVocabSubscription(response.data['vocab_subscription']);
        });
  },
  methods: {
    ...mapMutations(['setVocabSubscription']),
    purchase(plan) {
      if (this.fetching) return;
      this.fetching = true;
      axios.post('/api/v1/core/student/create_checkout_session/', {plan: plan})
          .then((response) => {
            return this.stripe.redirectToCheckout({
              sessionId: response.data.sessionId,
            });
          })
          .then((response) => {
            this.fetching = false;
          })
          .catch((error) => {
            this.fetching = false;
            alert(error.response.data.detail);
          });
    },
    cancel() {},
  },
};
</script>


<style lang="scss" scoped>
  .billing__component--form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;
    background: #fff;
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
    &-row {
      width: 60%;
    }
    &-title{
      text-align: left;
      margin-bottom: 6px;
    }
    &-input{
      background-color: #eee;
      padding: 15px;
      width: 100%;
      display: block;
      border-radius: 10px;
      outline: none;
      border: 2px solid #ddd;
      margin-bottom: 12px;
      &:focus {
        border: 2px solid #ac2fb1;
      }
    }
  }

  .error-message {
    color: red;
    position: absolute;
    top: 0;
    right: 0;
  }

  .btn-submit {
    margin-top: 2.5rem;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    display: block;
    border: 2px solid #1c6cd9;
    width: 200px;
    height: 50px;
    border-radius: .5rem;
    background: #5881fc;
    padding: 0 25px;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: all 0.2s;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #58bdfc;
      border: 2px solid #3a9edd;
    }
    &:active {
      transform: scale(1.02);
      background: #5881fc;
      border: 2px solid #1c6cd9;
    }
  }

  @media only screen and (max-width: 1366px),
            screen and (max-height: 870px)  {
              .billing__component--form-row {
                width: 70%;
              }
            }
</style>
