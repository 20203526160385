<template>
  <span class="loading-dots">
    <span v-for="i in 3" :key="i" class="dot" :class="{active: i <= activeDots }"></span>
  </span>
</template>

<script>
export default {
  name: 'LoadingDots',
  data() {
    return {
      activeDots: 1,
      interval: null,
    };
  },
  mounted() {
    this.startAnimation();
  },
  beforeDestroy() {
    this.stopAnimation();
  },
  methods: {
    startAnimation() {
      this.interval = setInterval(() => {
        this.activeDots = (this.activeDots % 3) + 1;
      }, 300);
    },
    stopAnimation() {
      clearInterval(this.interval);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-dots {
  display: inline-flex;
  align-items: center;
  height: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 4px;
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.dot.active {
  opacity: 1;
}
</style>
